export default {
  namespaced: true,
  state: {
    globalSpinnerVisible: false,
  },
  getters: {},

  actions: {
    showSpinner({ commit }) {
      commit("SHOW_SPINNER");
    },
    hideSpinner({ commit }) {
      commit("HIDE_SPINNER");
    },
  },

  mutations: {
    SHOW_SPINNER(state) {
      state.globalSpinnerVisible = true;
    },
    HIDE_SPINNER(state) {
      state.globalSpinnerVisible = false;
    },
  },
};

// const store = {
//     state: {
//       globalSpinnerVisible: false,
//     },
//     mutations: {
//       SHOW_SPINNER(state) {
//         state.globalSpinnerVisible = true;
//       },
//       HIDE_SPINNER(state) {
//         state.globalSpinnerVisible = false;
//       },
//     },
//     actions: {
//       showSpinner({ commit }) {
//         commit("SHOW_SPINNER");
//       },
//       hideSpinner({ commit }) {
//         commit("HIDE_SPINNER");
//       },
//     },
//   };

//   export default store;
