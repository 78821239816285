// import { axios } from "vue/types/umd";

// import { axios } from "vue/types/umd";

// import { axios } from "vue/types/umd";

export const AdmissionOfficer = {
  namespaced: true,
  state: {
    applicants: [],
    applicant: {},
    applications: [],
  },
  mutations: {
    setApplicants(state, payLoad) {
      state.applicants = payLoad;
    },
    setApplicant(state, payLoad) {
      state.applicant = payLoad;
    },
    setApplications(state, payLoad) {
      state.applications = payLoad;
    },
  },
  actions: {
    reset_for_app_adit(context, details) {
      context.dispatch("spinner/showSpinner", null, { root: true });
      return new Promise((res, rej) => {
        axios
          .post("/admin/app/reset/for/edit", details)
          .then((response) => {
            res(response);
          })
          .catch((err) => {
            context.dispatch("spinner/hideSpinner", null, { root: true });
            rej(err);
          });
      });
    },

    reset_application_for_pg_actions(context, details) {
      context.dispatch("spinner/showSpinner", null, { root: true });
      return new Promise((res, rej) => {
        axios
          .post("/admin/pg/adms/action/reset", details)
          .then((response) => {
            res(response);
          })
          .catch((err) => {
            context.dispatch("spinner/hideSpinner", null, { root: true });
            rej(err);
          });
      });
    },

    reset_recommendation_status(context, details) {
      context.dispatch("spinner/showSpinner", null, { root: true });
      return new Promise((res, rej) => {
        axios
          .post("/admin/pgcoord/adms/reset", details)
          .then((response) => {
            res(response);
          })
          .catch((err) => {
            context.dispatch("spinner/hideSpinner", null, { root: true });
            rej(err);
          });
      });
    },
    getApplicants(context) {
      return new Promise((res, rej) => {
        axios
          .post("/admin/getApplicants")
          .then((response) => {
            context.commit("setApplications", response.data.applicants);
            res(response.data.applicants);
          })
          .catch((err) => {
            rej(false);
          });
      });
    },
    getApplications(context, applicationProps) {
      // applicationProps ->status ->fromdate to datedate
      return new Promise((res, rej) => {
        if (
          applicationProps.status != "recommended" &&
          applicationProps.status != "nrecommened"
        ) {
          axios
            .post("/admin/getApplications", {
              status: applicationProps.status,
              deptName: context.rootState.user.deptName,
            })
            .then((response) => {
              context.commit("setApplications", response.data.applications);
              res(response.data.applications);
            })
            .catch((err) => {
              rej(false);
            });
        }
        if (applicationProps.status == "nrecommened") {
          axios
            .post("admin/pgcoord/disapproved/recommendation/list", {
              deptName: context.rootState.user.deptName,
            })
            .then((response) => {
              context.commit("setApplications", response.data.applications);
              res(response.data.applications);
              return true;
            })
            .catch((err) => {
              rej(false);
              return false;
            });
        }
        if (applicationProps.status == "recommended") {
          axios
            .post("admin/pgcoord/approved/recommendation/list", {
              deptName: context.rootState.user.deptName,
            })
            .then((response) => {
              context.commit("setApplications", response.data.applications);
              res(response.data.applications);
              return true;
            })
            .catch((err) => {
              rej(false);
              return false;
            });
        }
      });
    },
    getApplicationGivenEmail(context, applicantEmail) {
      return new Promise((res, rej) => {
        axios
          .post("/admin/getApplicationGivenEmail", applicantEmail)
          .then((response) => {
            context.commit("setApplications", response.data.applications);
            res(response.data.applications);
            return true;
          })
          .catch((err) => {
            rej(false);
            return false;
          });
      });
    },
    getPaginatedApplications(context, applicationProps) {
      // applicationProps ->status ->fromdate to datedate
      return new Promise((res, rej) => {
        if (
          applicationProps.status != "recommended" &&
          applicationProps.status != "nrecommened"
        ) {
          axios
            .post("/admin/getPaginatedApplications", {
              status: applicationProps.status,
              deptName: context.rootState.user.deptName,
              currentPage: applicationProps.currentPage,
              perPage: applicationProps.perPage,
            })
            .then((response) => {
              context.commit("setApplications", response.data.applications);
              res(response.data.applications);
            })
            .catch((err) => {
              rej(err.response.data);
            });
        }
        if (applicationProps.status == "nrecommened") {
          axios
            .post("admin/pgcoord/disapproved/recommendation/list", {
              deptName: context.rootState.user.deptName,
            })
            .then((response) => {
              context.commit("setApplications", response.data.applications);
              res(response.data.applications);
              return true;
            })
            .catch((err) => {
              rej(false);
              return false;
            });
        }
        if (applicationProps.status == "recommended") {
          axios
            .post("admin/pgcoord/approved/recommendation/list", {
              deptName: context.rootState.user.deptName,
            })
            .then((response) => {
              context.commit("setApplications", response.data.applications);
              res(response.data.applications);
              return true;
            })
            .catch((err) => {
              rej(false);
              return false;
            });
        }
      });
    },

    recommend_application(context, details) {
      context.dispatch("spinner/showSpinner", null, { root: true });
      return new Promise((res, rej) => {
        axios
          .post("/admin/pgcoord/adms/recommend", details)
          .then((resp) => {
            res(resp);
          })
          .catch((err) => {
            context.dispatch("spinner/hideSpinner", null, { root: true });
            rej(err);
          });
      });
    },

    reject_application(context, details) {
      context.dispatch("spinner/showSpinner", null, { root: true });
      return new Promise((res, rej) => {
        axios
          .post("/admin/pgcoord/adms/reject", details)
          .then((resp) => {
            res(resp);
          })
          .catch((err) => {
            context.dispatch("spinner/hideSpinner", null, { root: true });
            rej(err);
          });
      });
    },

    getApprovedApplications(context, params) {
      console.log(params);
      context.dispatch("spinner/showSpinner", null, { root: true });
      return new Promise((res, rej) => {
        axios
          .get("/admin/get/approved/applications", { params })
          .then((resp) => {
            res(resp.data);
          })
          .catch((err) => {
            context.dispatch("spinner/hideSpinner", null, { root: true });
            rej(err);
          });
      });
    },
    getForms(context, applicationId) {
      return new Promise((res, rej) => {
        axios
          .post("admin/getForms", { applicationId: applicationId })
          .then((response) => {
            res(response);
          })
          .catch((err) => {
            rej(err);
          });
      });
    },
    approveAdmission(context, details) {
      context.dispatch("spinner/showSpinner", null, { root: true });
      return new Promise((res, rej) => {
        axios
          .post("admin/admissionApproved", details)
          .then((response) => {
            res(response);
          })
          .catch((err) => {
            context.dispatch("spinner/hideSpinner", null, { root: true });
            rej(err);
          });
      });
    },
    denyAdmission(context, details) {
      return new Promise((res, rej) => {
        axios
          .post("admin/admissionApproved", details)
          .then((response) => {
            res(response);
          })
          .catch((err) => {
            rej(err);
          });
      });
    },
  },
};
